export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'regionCode',
    label: 'field.region',
    width: 2500,
  },
  {
    key: 'agentCode',
    label: 'field.agent',
    width: 2500,
    hideToggle: true,
  },
  {
    key: 'image',
    label: 'field.image',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
    width: 2000,
    exportKey: 'imageId',
    isImage: true,
  },
  {
    key: 'name',
    label: 'field.outletName',
    localization: true,
    width: 6000,
    hideToggle: true,
  },
  {
    key: 'ownerName',
    label: 'field.ownerName',
    width: 6000,
    hideToggle: true,
  },
  {
    key: 'salespersonIdCard',
    label: 'field.idCard',
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    hideToggle: true,
  },
  {
    key: 'contactNumber',
    label: 'field.contactNumber',
  },
  {
    key: 'secondaryNumber',
    label: 'field.secondaryNumber',
  },
  {
    key: 'outletSegmentName',
    label: 'field.outletSegment',
    localization: true,
  },
  {
    key: 'houseNo',
    label: 'field.houseNo',
    width: 3000,
  },
  {
    key: 'streetNo',
    label: 'field.streetNo',
    width: 3000,
  },
  {
    key: 'villageName',
    label: 'field.village',
    localization: true,
  },
  {
    key: 'communeName',
    label: 'field.commune',
    localization: true,
  },
  {
    key: 'districtName',
    label: 'field.district',
    localization: true,
  },
  {
    key: 'provinceName',
    label: 'field.province',
    localization: true,
  },
  {
    key: 'address',
    label: 'field.address',
    localization: true,
    width: 10000,
  },
  {
    key: 'latitude',
    label: 'field.latitude',
  },
  {
    key: 'longitude',
    label: 'field.longitude',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createdDate',
    hideToggle: true,
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
