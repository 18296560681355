export default [
  {
    key: 'regionId',
    label: 'field.region',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'region',
    selectionKey: 'id',
    selectionLabel: 'regionCode',
  },
  {
    key: 'agentId',
    label: 'field.agent',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'agent',
    selectionKey: 'id',
    selectionLabel: 'agentCode',
  },
  {
    key: 'salespersonId',
    label: 'field.salesperson',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
  },
  {
    key: 'ownerName',
    label: 'field.ownerName',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    type: 'text',
    operator: '%',
  },
  {
    key: 'outletSegmentId',
    label: 'field.outletSegment',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'outletSegment',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'channel',
    label: 'field.channel',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: 'field.onPremise', value: '1' },
      { text: 'field.offPremise', value: '2' },
    ],
  },
  {
    key: 'provinceId',
    label: 'field.province',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'province',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'districtId',
    label: 'field.district',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'district',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'communeId',
    label: 'field.commune',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'commune',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'villageId',
    label: 'field.village',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'village',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'createdAt',
    label: 'field.createdDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
]
